.footer{
    background-color: black;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
    position: static;
    overflow: auto ;
    bottom: 0;
    padding-top: 80px;  
}

.footer-text{
    color: white;
    display: flex;
    justify-content: center;
}

.logo-row{
  display: flex;
  justify-content: center;
}


.fa {
    padding: 8px;
    font-size: 30px;
    width: 40px;
    text-align: center;
    text-decoration: none;
    margin: 10px 5px;
    border-radius: 50%; 
  }

.fa:hover {
    opacity: 0.7;
}

#fb-logo {
  position: relative;
  background: #3B5998;
  color: white;
}

#insta-logo{
  position: relative;
  background: #125688;
  color: white;
}
