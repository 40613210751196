.group_picture{
  background-image: url("./../../static/group2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  height: 120vh;
  position: relative;
  padding: 0;
}


.content-below{
  background-color: beige;
  position: relative;
  display: inline-block;
}


.paragraph-1{
padding-top: 30px;
margin-bottom: 30px;
margin-left: 50px;
margin-right: 50px;
}

.paragraph-2 {
margin-bottom: 40px;
margin-bottom: 40px;
margin-left: 50px;
margin-right: 50px;

}