* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.app{
  height: 100%;
  position: relative;
}

.restriced-container{
  height: 80vh;
  background-color: white;
}




