.admin-medlemmar-container{
    background-color: beige;
    height: 79vh;
    width: 100%;
    overflow: scroll;
}

.headline-admin-medlemmar{
    text-align: center;
    
}

#nav-link-admin{
    background-color: lightgray;
    color: black;
    text-align: center;
    border: solid;
    border-radius: 20px;
    padding-top: 1.2vh;
    margin-left: 2vh;
    margin-top: 1vh;
}

#nav-item-admin{
    background-color: beige;
    display: flex;
    justify-content: center;
}

#nav-link-hamburger-admin{
    display: flex;
    justify-content: center;
}

#nav-link-hamburger-admin:hover{
    text-decoration: underline;
}


#nav-link-admin:hover{
    background-color: darkgray;
}

#admin-member-navbar{
    background-color: beige;
}

#member-form{
    /* text-align: center; */
}


.memberList-container{

    background-color: beige;
    padding-top: 5vh;
    padding-bottom: 25vh;
}




.search-members{
    border-radius: 10px;
    width: 15vw;
    height: 6vh;
    border: solid;
    /* border-color: black; */
}
