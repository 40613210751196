.admin-home-container{
    background-color: beige;
    height: 79vh;
    width: 100%;
    overflow: hidden;
}

.headline{
    text-align: center;
}

#nav-link-logout{
    background-color: lightgray;
    color: black;
    text-align: center;
    border: solid;
    border-radius: 20px;
    padding-top: 1.2vh;
    margin-left: 2vh;
    margin-top: 1vh;
}

#nav-link-logout:hover{
    background-color: darkgray;
}

.logout_link:hover{
    text-decoration: underline;
}

.alternative-row{
    padding-top: 30vh;
    padding-bottom: 10vh;
}

.alternative-box{
    text-align: center;
    background-color: lightgray;
    position: relative;
    height: 10vh;
    border-radius: 20px;
    padding-top: 2.5vh;
    border: solid;
}

.alternative-box:hover {
    background-color: darkgray;
}

#alt-1{
    margin-left: 15vh;
    margin-right: 5vh;
}

#alt-2{
    margin-right: 15vh;
}


.alternative-2 :hover{
    background-color: darkgray;



}