.navbar{
    position:relative;
    overflow: hidden; 
}

.navLogo{
    border-radius: 75px;
    height: 150px;
    width: 150px;
    margin-left: 40px;
    flex: auto;
  }


#nav-item{
    background-color: #f1b765;
    width: 12vw;
    border-radius: 20px;
    text-align: center;
    margin-right: 5vw;
    border-style: solid;
    border-color: black;
    
}
#nav-item :hover{
    /* color: black;  */
}

#navbar-burger{
    background-color: #f1b765;
}



#nav-link{
    color: black;
    padding-left: 10px;
}

.hidden-spacer {
    height: 1px;
    padding-top: 50px;
    background-color: beige;
    
}

#small-navbar{
    color: black;
    background-color: #f1b765;
    width: 170vh;
    margin-left: 0;
    margin-right: 1px;
    
}
#nav-item-small{
    background-color: #f1b765;
    display: flex;
    justify-content: center;
}


#nav-item-small :hover{
    color: black;
}

#nav-link-small{
    display: flex;
    justify-content: center;
}

.navLogo-small{
    border-radius: 75px;
    height: 75px;
    width: 75px;
    
    flex: auto;
}

#nav-burger{
    color: black;
    
}